import React, { useEffect, useContext } from 'react'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom'

import { DataContext } from '../../../context/DataContext'
import mailIcon from '../../../assets/icons/icon_contactonopermitido.svg'
import { usePaginateQuery } from '../../../hooks/usePaginateQuery'
import { getpqrsOfUnregisteredUsers } from '../../../utils/userQueries'
import { dataTypes } from '../../../context/reducers/dataReducer/dataTypes'

import './EntradasLayout.css'
import { ScriptContext } from '../../../context/ScriptsContext'

export const EntradasLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, newEntries } = dataState.operative
  const { pqrsOfUnregisteredUsers } = newEntries

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    pqrsOfUnregisteredUsers,
    'pqrsOfUnregisteredUsers',
    getpqrsOfUnregisteredUsers,
    dataTypes.updatePqrsOfUnregisteredUsers,
    idToken,
    dataContext
  )

  useEffect(() => {
    dataDispatch({
      type: dataTypes.setPaginationOfUnreadPqrs,
      payload: {
        paginationValues,
        setPaginationValues,
        isNextActive,
        setIsNextActive,
        isPrevActive,
        setIsPrevActive
      }
    })
  }, [paginationValues])

  const { pathname } = useLocation()

  return (
    <section className="entradasLayoutContainer">
      <div className="entriesHeaderContainer">
        {pathname.includes('UsuariosNoRegistrados')
          ? (
          <NavLink className="entriesActiveHeader" to="UsuariosNoRegistrados">
            <p className="entriesPanelParagraph">
              {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.unregisteredUsers : ''}
            </p>
          </NavLink>
            )
          : pathname.endsWith('ValidacionUsuario') ||
          pathname.endsWith('EliminarUsuario')
            ? (
          <NavLink className="entriesActiveHeader" to="./NuevosUsuariosValidacionUsuario">
            <p className="entriesPanelParagraph">
              {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.userValidation : ''}
            </p>
          </NavLink>
              )
            : pathname.endsWith('ValidacionEntidad')
              ? (
          <NavLink className="entriesActiveHeader" to="./NuevasEntidades/ValidacionEntidad">
            <p className="entriesPanelParagraph">
              {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.entityValidation : ''}
            </p>
          </NavLink>
                )
              : (
          <div className="entriesHeaderContainerNavlinks">
            <NavLink
              className={({ isActive }) =>
                isActive ? 'entriesActiveHeader' : ''
              }
              end
              to=""
            >
              <p className="entriesPanelParagraph">
                {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.newUsersEntries : ''}
              </p>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'entriesActiveHeader' : ''
              }
              to="NuevasEntidades"
            >
              <p className="entriesPanelParagraph">
                {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.newEntitiesEntries : ''}
              </p>
            </NavLink>

          </div>
                )}
        <Link
          className="entriesHeaderContainerButton"
          to="./UsuariosNoRegistrados"
        >
          <img
            alt={scriptsSave && scriptsSave.entradasLayoutAdminStrings ? scriptsSave.entradasLayoutAdminStrings.altImageTxt1 : ''}
            className="entriesHeaderContainerImg"
            src={mailIcon}
          />
          {
            paginationValues.totalItems > 0 &&
            paginationValues.totalItems < 100
              ? (
              <span className="entriesHeaderContainerUnreadPqrs">
                {paginationValues.totalItems}
              </span>
                )
              : paginationValues.totalItems >= 100
                ? (
              <span className="entriesHeaderContainerUnreadPqrs">
                {scriptsSave && scriptsSave.entradasLayoutAdminStrings ? scriptsSave.entradasLayoutAdminStrings.maxUnreadPQRSTxt1 : ''}
              </span>
                  )
                : null
          }
        </Link>
      </div>

      <hr className="hrEntriesPanel" />
      <Outlet />
    </section>
  )
}
